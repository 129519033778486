import React, { useState } from "react";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/styles/hover.scss";
import { useTranslation } from "react-i18next";
import { Menu } from "primereact/menu";

const LandingPage = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("userName");
  const { t } = useTranslation();

  const [menuVisible, setMenuVisible] = useState(false);
  const items = [
    {
      label: t("Login"),
      icon: "pi pi-sign-in",
      command: () => navigate("/login")
    },
    {
      label: t("SignUp"),
      icon: "pi pi-user-plus",
      command: () => navigate("/register")
    }
  ];

  return (
    <div className="surface-0 flex justify-content-center">
      <div id="home" className="w-screen landing-wrapper p-fluid overflow-hidden">
        <div
          className="py-1 px-4 mx-0 flex align-items-center justify-content-between relative lg:static"
          style={{ backgroundColor: "#114d6b", height: "60px" }}
        >
          <h2 className="m-0" style={{ color: "#ffffff" }}>
            Beraberiz.Biz
          </h2>
          <div className="lg:flex ml-auto">
            <div className="md:flex hidden">
              <Button
                label={username ? username : t("Login")}
                text
                rounded
                className="border-none font-light line-height-2 text-blue-500 md:mr-2"
                style={{
                  backgroundColor: "#ffffff", // White background
                  color: "#114d6b", // Text color #114d6b
                  border: "3px solid #114d6b",
                  height: "40px"
                }}
                onClick={() => navigate("/login")}
              />
              <Button
                label={t("SignUp")}
                rounded
                className="border-none font-light line-height-2 bg-blue-500 text-white"
                style={{
                  backgroundColor: "#ffffff", // White background
                  color: "#114d6b", // Text color #114d6b
                  border: "3px solid #114d6b",
                  height: "40px"
                }}
                onClick={() => navigate("/register")}
              />
            </div>

            {/* Hamburger Menu Butonları */}
            <div className="md:hidden">
              <Button
                icon="pi pi-bars"
                onClick={() => setMenuVisible(!menuVisible)}
                className="p-button-rounded p-button-outlined"
                style={{ backgroundColor: "#ffffff", color: "#114d6b", border: "3px solid #114d6b" }}
              />
              {menuVisible && <Menu model={items} />}
            </div>
          </div>
        </div>

        <div
          id="features"
          className="flex justify-content-center align-items-center py-4 px-4 lg:px-6 border-green-300 rounded-lg shadow-md"
          style={{
            backgroundColor: "#114d6b",
            backgroundImage: "url('images/ArkaPlan.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "30rem"
          }}
        >
          <div className="text-center">
            <h1 className="text-3xl font-bold" style={{ color: "#ffffff" }}>
              Merhaba
            </h1>
            <div className="mt-2" style={{ color: "#ffffff" }}>
              Hoşgeldiniz,
              <p className="mt-2" style={{ color: "#ffffff" }}></p>
              Bu site beraber çalışmanın değerini bilen bilim insanlarına adanmıştır. Burada;
              <p className="mt-2" style={{ color: "#ffffff" }}></p>
              Anketlere katılıp akademik konularda fikirlerinizi paylaşabilirsiniz,
              <p className="mt-2" style={{ color: "#ffffff" }}></p>
              Akademik projelerde görevlerinizi izleyip, işbirlikleri yapabilirsiniz.
              <p className="mt-2" style={{ color: "#ffffff" }}></p>
              Derslere katılım yapabilirsiniz…
            </div>
          </div>
        </div>

        <div id="features" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "12rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/hesaplama.jpg" className="shadow-2 w-10rem h-10rem mr-3" alt="image1"/>
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Dinamik Modelleme</h5>
                    <span className="text-600 text-sm"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "12rem", padding: "1rem" }}
                onClick={() => navigate("/app/malzeme-kaynak", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/rapor.jpg" className="shadow-2 w-10rem h-10rem mr-3" alt="image2" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Raporlar</h5>
                    <span className="text-600 text-sm"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div
                className="card hover-card cursor-pointer"
                style={{ height: "12rem", padding: "1rem" }}
                onClick={() => navigate("/app/egitim", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/landing-page/egitim.jpg" className="shadow-2 w-10rem h-10rem mr-3" alt="image3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Eğitim</h5>
                    <span className="text-600 text-sm"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-4 px-4 mx-0 mt-4 lg:mx-4">
          <div className="grid justify-content-between">
            <div className="col-12 md:col-2" style={{ marginTop: "-1.5rem" }}>
              <Link
                to="/"
                className="flex align-items-center justify-content-center md:justify-content-start  cursor-pointer"
              >
                <img src="images/medeasofticon.jpg" alt="footer sections" width="80" height="80" className="mr-2" />
                <span className="font-medium text-3xl text-900">MedeaSoft</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
