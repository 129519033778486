import { Route, Routes } from "react-router-dom";
import App from "../App";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import LandingPage from "../pages/landing/LandingPage";
import KarbonAyakIzi from "../pages/hesaplama/KarbonAyakIzi";
import Grafik from "../pages/analiz/Grafik";
import Egitim from "../pages/egitim/Egitim";
import Raporlama from "../pages/raporlama/Raporlama";
import IsSurekliligi from "../pages/issurekliligi/IsSurekliligi";
import NotFoundPage from "../pages/not-found/NotFound";
import OsbukBulten from "../pages/bulten/OsbukBulten";
import ProtectedRoute from "./ProtectedRoute";
import ForumPage from "../pages/forum/ForumPage";
import Forum from "../pages/forum/Forum";
// import ResetPassword from "../pages/reset-password/ResetPassword";
// import { HasPermission } from "../utils/auth";
// import { Permission } from "../services/auth/permissions";
// import NotFoundPage from "../pages/not-found/NotFound";
// import AccessDeniedPage from "../pages/access-denied/AccessDeniedPage";
// import EmailConfirmPage from "../pages/auth/EmailConfirm";
// import ChangeEmailPage from "../pages/auth/ChangeEmail";
// import NewEmailConfirmPage from "../pages/auth/NewEmailConfirm";
// import ChangePasswordPage from "../pages/auth/ChangePassword";
// import ProfilePage from "../pages/auth/Profile";
// import RolePage from "../pages/role/Role";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/app" element={<App />}>
        <Route path="karbonayakizi" element={<KarbonAyakIzi />} />
        <Route path="grafik" element={<Grafik />} />
        <Route path="egitim" element={<Egitim />} />
        <Route path="is-surekliligi" element={<IsSurekliligi />} />
        <Route path="malzeme-kaynak" element={<Raporlama />} />
        <Route path="bulten" element={<OsbukBulten />} />
        {/* <Route path="profile" element={<ProfilePage />} /> */}

        <Route path="forum-page" element={<ForumPage />} />
        <Route path="forum" element={<Forum />} />
        

      </Route>


      <Route path="/not-found" element={<NotFoundPage />} />
      {/*<Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/email-confirm" element={<EmailConfirmPage />} />
      <Route path="/change-email-confirm" element={<NewEmailConfirmPage />} />
      <Route path="/app" element={<App />}>
        <Route
          path="profile"
          element={
            <HasPermission>
              <ProfilePage />
            </HasPermission>
          }
        />
        <Route
          path="change-email"
          element={
            <HasPermission>
              <ChangeEmailPage />
            </HasPermission>
          }
        />
        <Route
          path="change-password"
          element={
            <HasPermission>
              <ChangePasswordPage />
            </HasPermission>
          }
        />
        <Route
          path="roles"
          element={
            <HasPermission permissions={[Permission.RoleDefault]}>
              <RolePage />
            </HasPermission>
          }
        />
      </Route>
      <Route path="/access-denied" element={<AccessDeniedPage />} />
      <Route path="/not-found" element={<NotFoundPage />} /> */}
    </Routes>
  );
};

export default AppRouter;
