import { useToast } from "../../utils/toast";
import { Fieldset } from "primereact/fieldset";
import { TabPanel, TabView } from "primereact/tabview";

const ForumPage = () => {
    const toast = useToast();
  
    const allTabs: any[] = [
        {
          title: "Dosya Bilgileri",
          name: "ayrinti_bilgileri",
          content: (
            <div>
              <h4>Dosya Bilgileri</h4>
              <p>Bu, "Dosya Bilgileri" sekmesinin içeriğidir.</p>
            </div>
          ),
          isActive: true
        },
        {
          title: "Dosya Hesabı",
          name: "hesap_bilgileri",
          content: (
            <div>
              <h4>Dosya Hesabı</h4>
              <p>Bu, "Dosya Hesabı" sekmesinin içeriğidir.</p>
            </div>
          ),
          isActive: true
        },
        {
          title: "Taraf Bilgileri",
          name: "taraf_bilgileri",
          content: (
            <div>
              <h4>Taraf Bilgileri</h4>
              <p>Bu, "Taraf Bilgileri" sekmesinin içeriğidir.</p>
            </div>
          ),
          isActive: true
        }
      ];
  
    return (
      <div className="card p-1 lg:p-5">
        <h3>Yunus</h3>       
        <Fieldset legend="Header">
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Fieldset>


      <div className="card p-1 lg:p-5">
        <TabView className="mt-3" scrollable>
          {allTabs
            .filter((tab) => tab.isActive === true )
            .map((tab: any) => {
              return (
                <TabPanel key={tab.title} header={tab.title}>
                  {tab.content}
                </TabPanel>
              );
            })}
        </TabView>
      </div>
      </div>
    );
  };

export default ForumPage;
