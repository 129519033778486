import React, { useContext } from "react";
import AppMenuitem from "./AppMenuitem";
import { LayoutContext } from "./context/layoutcontext";
import { MenuProvider } from "./context/menucontext";
import { AppMenuItem } from "../types/types";

const AppMenu = () => {
  //const { layoutConfig } = useContext(LayoutContext);
  const masaNo = localStorage.getItem("anketNo") || "1";

  const model: AppMenuItem[] = [
    {
      label: "Ana Sayfa",
      items: [{ label: "Ana Sayfa", icon: "pi pi-fw pi-home", to: "/" }]
    },
    {
      label: "Sayfalar",
      icon: "pi pi-fw pi-briefcase",
      to: "/pages",
      items: [
        {
          label: "Dijital Modelleme",
          icon: "pi pi-fw pi-calculator",
          to: "/app/karbonayakizi"
        },
        {
          label: "Raporlar",
          icon: "pi pi-fw pi-file",
          to: "/app/malzeme-kaynak"
        },
        // {
        //   label: "Analiz",
        //   icon: "pi pi-fw pi-chart-scatter",
        //   to: "/app/grafik"
        // },
        {
          label: "Eğitim",
          icon: "pi pi-fw pi-user",
          to: "/app/egitim"
        },
      ]
    }
  ];

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, i) => {
          return !item?.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
    </MenuProvider>
  );
};

export default AppMenu;
