import React, { useContext, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { useFormik } from "formik";
import { Card } from "primereact/card";
import aosbLogo from "../../assets/images/aosb_ces_logo.jpg";
import formService from "../../services/forms/form.service";
import { AnketRequestDto, HesaplamaAracAnketRequestDto } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import ReactPlayer from "react-player";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
const IsSurekliligi = () => {
  const toast = useToast();

  const formik = useFormik<AnketRequestDto>({
    initialValues: {
      result1: ""
    },
    validate: values => {
      const errors: any = {};
      if (!values.result1 || values.result1 === "") {
        errors.result1 = "Bir seçenek şeçiniz.";
      }

      return errors;
    },
    onSubmit: async values => {
      formService.afadAnket(values).then(res => {
        if (res?.result?.error === null) {
          toast.show("Anket kaydedildi.", "success");
        } else {
          //toast.show("Anket kaydedilemedi", "error");
        }
      });
    }
  });

  const options = [
    { label: "Evet", value: "evet" },
    { label: "Hayır", value: "hayir" }
  ];

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const value = e.value;
    let newAnalysisAreas: string = "";

    if (e.checked) {
      newAnalysisAreas = value;
    }

    formik.setFieldValue("result1", newAnalysisAreas);
  };

  return (
    <>
      <div className="card">
        <div className="flex justify-content-center">
          <div
            className="flex align-items-center"
            style={{
              width: "100%",
              position: "relative",
              aspectRatio: "16 / 9", // 16:9 oranı
              maxHeight: "80vh", // İsterseniz maksimum yükseklik ekleyebilirsiniz
              overflow: "hidden" // Taşmayı engelle
            }}
          >
            <ReactPlayer
              url="https://www.youtube.com/watch?v=ZSpti-fHj5E"
              controls
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">İş Sürekliliği Planı Anketi</div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-column align-items-start">
              <h5>OSB’nizin afetlerde üretime devam etmesi için bir planınız var mı?</h5>
              <div className="grid">
                {options.map(option => (
                  <div key={option.value} className="col-12 ml-3">
                    <Checkbox
                      inputId={option.value}
                      value={option.value}
                      onChange={handleCheckboxChange}
                      checked={formik.values.result1.includes(option.value)}
                    />
                    <label htmlFor={option.value} className="ml-2">
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>

              {formik.errors.result1 && <div className="p-error">{formik.errors.result1}</div>}
              <Button label="Gönder" type="submit" className="mt-3" />
            </div>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="w-full surface-card px-5 sm:px-8" style={{ borderRadius: "53px" }}>
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Protokol Yapılan Firmalar</div>
          </div>
          <div className="flex flex-column align-items-center justify-content-center ">
            <div className="grid grid-nogutter justify-content-center w-full p-2">
              <Card
                header={
                  <div className="flex flex-column align-items-center justify-content-center">
                    <span className="mb-2 font-bold text-xl text-black-alpha-90">ASO 2-3 OSB</span>
                    <img className="w-12rem h-12rem mt-2" alt="Card" src="https://www.asobcev.com.tr/images/logo.png" />
                  </div>
                }
                className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
                onClick={() => (window.location.href = "https://www.asobcev.com.tr/")}
              ></Card>
              <Card
                header={
                  <div className="flex flex-column align-items-center justify-content-center">
                    <span className="mb-2 font-bold text-xl text-black-alpha-90">ADANA OSB</span>
                    <img className="w-12rem h-8rem mt-2" alt="Card" src={aosbLogo} />
                  </div>
                }
                className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
                onClick={() => (window.location.href = "http://www.aosb-co2.com/")}
              ></Card>
              <Card
                header={
                  <div className="flex flex-column align-items-center justify-content-center">
                    <span className="mb-2 font-bold text-xl text-black-alpha-90">TOBB MEYBEM</span>
                    <img
                      className="w-12rem h-12rem mt-2"
                      alt="Card"
                      src="https://meybem.org.tr/wp-content/uploads/2023/11/meybem-logo.svg"
                    />
                  </div>
                }
                className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
                onClick={() =>
                  (window.location.href = "https://meybem.org.tr/kurumsal-karbon-ayak-izi-egitimi-ankara/")
                }
              ></Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsSurekliligi;
